<template lang="html">
  <header id="header">

    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo-h-1.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fal fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- <b-navbar-nav>
              <b-nav-item href="#">Link</b-nav-item>
            </b-navbar-nav> -->

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="simple-item" to="/">Inicio</b-nav-item>
              <b-nav-item class="simple-item" to="/empresa">Empresa</b-nav-item>
              <!-- <b-nav-item class="simple-item" to="/patrocinadores">Patrocinadores</b-nav-item> -->
              <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>
              <b-nav-item class="btn-item" to="/afilia-tus-canchas">Afilia tus canchas</b-nav-item>

              <li class="nav-item networks-item">
                <div class="nav-link">
                  <span>Disponible</span>
                  <a class="btn-network" target="_blank" :href="$root.appsLinks.ios"><i class="fab fa-apple"></i></a>
                  <a class="btn-network" target="_blank" :href="$root.appsLinks.android"><i class="fab fa-google-play"></i></a>
                </div>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

  </header>
</template>

<script>
export default {
}
</script>
