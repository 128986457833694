<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <h2>Newsletter</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Escribe tu correo"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Suscribirte</b-button>
      </b-form>
    </section>

  	<section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
					<div class="col-lg-3 col-footer col-logo">
						<router-link class="btn-logo" to="/">
							<img src="public/images/logo-h-1.svg" alt="Padel+">
						</router-link>
					</div>

					<div class="col-sm-6 col-lg-3 col-footer">
						<!-- <h5>Links de acceso</h5> -->

						<p class="f-w-300"><router-link class="link-footer" to="/empresa">Empresa</router-link></p>
						<!-- <p class="mt-lg-1"><router-link class="link-footer" to="">Patrocinadores</router-link></p> -->
						<p class="mt-lg-1"><router-link class="link-footer" to="/contacto">Contacto</router-link></p>
						<p class="mt-lg-1"><router-link class="link-footer" to="/afilia-tus-canchas">Afilia tus canchas</router-link></p>
					</div>

					<div class="col-sm-6 col-lg-3 col-footer">
						<!-- <h5>Información</h5> -->

						<p class="mt-lg-1"><router-link class="link-footer" to="">Términos y condiciones</router-link></p>
						<p class="mt-lg-1"><router-link class="link-footer" to="/aviso-de-privacidad">Aviso de privacidad</router-link></p>

						<p class="mt-2 copy-text">
							Todos los derechos reservados
						</p>
					</div>

          <div class="col-lg-3 col-footer col-copyright align-self-center">
            <p class="mb-3">
              <a class="t-150 btn-network" target="_blank" :href="$root.appsLinks.ios">
                <span><i class="fab fa-apple"></i></span>
              </a>
              <a class="t-150 btn-network" target="_blank" :href="$root.appsLinks.android">
                <span><i class="fab fa-google-play"></i></span>
              </a>
							<a class="t-150 btn-network" href="#">
								<span><i class="fab fa-facebook-f"></i></span>
							</a>
              <a class="t-150 btn-network" href="#">
                <span><i class="fab fa-instagram"></i></span>
              </a>
            </p>

            <p class="p-sm">
              Calle #2854,  Col. Bosques de la victoria,<br />
							C.P. 45050, Guadalajara, Jalisco
            </p>
            <p class="mt-2 p-sm">
              Tel. (33) 3333 3333
            </p>
          </div>
  			</div>
      </div>
  	</section>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  }
}
</script>
