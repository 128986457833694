<template lang="html">
  <div id="affiliation-page">

    <section class="container-fluid main-section">
      <div class="row align-items-center">
        <div class="col-md-5 col-xl-6 col-image">
          <!-- <img src="public/images/pages/about-us/image.jpg"> -->
        </div>

        <b-form class="col-md-7 col-xl-6 col-info" @submit="onSubmit">
          <div class="box-middle">
            <h2 class="mb-3 title-st-1" v-if="step <= 4">Registra tu cancha o club</h2>
              <p v-if="step <= 4"><b>Afíliate, agiliza y simplifica el manejo de reservaciones y agenda de tus canchas.</b><br>Cargo mensual de $990.00 M.N. Más IVA  y el 5% de comisión por cada transacción de reservación por cada partido.<br>Ademas de soporte tecnico y comercial ilimitado, asesoría y acompañamiento.</p>
              <br>
            <div class="box-n-step" v-if="step <= 3">
              <h5>Paso {{ step }}</h5>

              <div class="box-dots">
                <i class="dot" v-bind:class="{ 'active' : step == 1 }"></i>
                <i class="dot" v-bind:class="{ 'active' : step == 2 }"></i>
                <i class="dot" v-bind:class="{ 'active' : step == 3 }"></i>
                <i class="dot" v-bind:class="{ 'active' : step == 4 }"></i>
              </div>
            </div>

            <!-- Step 1 -->
            <div class="box-step step-1-2" v-show="step == 1">
              <div>
                <h5 class="mb-3 line-title"><span>Sobre tu cancha o club</span></h5>
                <div class="row justify-content-center">
                  <div class="col-12 col-form">
                    <b-form-group class="custom-group-1"
                      label="Agregar foto de tu logo:">
                      <b-form-file  v-model="form.fotoLogo" accept="image/*" plain name="imagefield"></b-form-file>
                    </b-form-group>

                    <b-form-group class="custom-group-1"
                      label="Nombre:">
                      <b-form-input
                        size="sm"
                        v-model="form.name_field"
                        type="text"
                        placeholder=""
                        
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group class="custom-group-1"
                      label="Correo electrónico:">
                      <b-form-input
                        size="sm"
                        v-model="form.email"
                        type="email"
                        placeholder=""
                        
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group class="custom-group-1"
                      label="Teléfono:">
                      <b-form-input
                        size="sm"
                        v-model="form.phone"
                        type="text"
                        placeholder=""
                        maxlength="10"
                        minlength="10"
                        
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <h5 class="mb-3 line-title"><span>Ubicación y dirección</span></h5>

                <div class="row">
        
                  <div id="searchemap" v-if="mapLoaded">
                      <b>Buscar Domicilio o dar click y/o mover pin para fijar ubicacion: </b><br>
                       <vue-google-autocomplete
                        id="map"
                        classname="form-control"
                        placeholder="Buscar..."
                        v-on:placechanged="getAddressData"
                        country="mx"
                        :value="currentPlace"
                         onfocus="this.removeAttribute('readonly');" 
                         autocomplete="off"
                      >
                      </vue-google-autocomplete>
                          
                  </div><br>
                        
                     
                  <GmapMap
                      :center="{lat:form.lat, lng:form.lng}"
                      :zoom="16"
                      map-type-id="terrain"
                      style=" height: 300px;width: 100%;"
                      @click="setPlace($event)"
                  >
                  <GmapMarker
                      :position="{lat:form.lat, lng:form.lng}"
                      :clickable="true"
                      :draggable="true"                             
                      @drag="updateCoordinates"/>
                  </GmapMap>
                
                  <!-- <b-form-group class="col-sm-8 custom-group-1"
                    label="Dirección:">
                    <b-form-input
                      size="sm"
                      v-model="form.street"
                      type="text"
                      placeholder=""
                      
                    ></b-form-input>
                  </b-form-group>

                  

                  <b-form-group class="col-6 col-sm-2 pl-0 custom-group-1"
                    label="No Ext.:">
                    <b-form-input
                      size="sm"
                      v-model="form.num_ext"
                      type="text"
                      placeholder=""
                      
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group class="col-6 col-sm-2 pl-sm-0 custom-group-1"
                    label="No Int.:">
                    <b-form-input
                      size="sm"
                      v-model="form.num_int"
                      type="text"
                      placeholder=""
                      
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group class="col-6 custom-group-1"
                    label="Estado:">
                    <b-form-select size="sm"  v-model="form.state_id">
                      <b-form-select-option :value="null">Seleccionar</b-form-select-option>
                      <b-form-select-option :value="state.id" v-for="(state,indx) in states" :key="indx">{{state.name}}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>

                  <b-form-group class="col-6 pl-0 custom-group-1"
                    label="Ciudad:">
                    <b-form-select size="sm"  v-model="form.town_id">
                      <b-form-select-option :value="null">Seleccionar</b-form-select-option>
                      <b-form-select-option :value="town.id" v-for="(town,indx) in towns" :key="indx">{{town.name}}</b-form-select-option>
                     
                    </b-form-select>
                  </b-form-group>

                  <b-form-group class="col-6 custom-group-1"
                    label="Colonia:">
                    <b-form-input
                      size="sm"
                      v-model="form.neighborhood"
                      type="text"
                      placeholder=""
                      
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group class="col-6 pl-0 custom-group-1"
                    label="Código postal:">
                    <b-form-input
                      size="sm"
                      v-model="form.zipcode"
                      type="text"
                      placeholder=""
                      
                    ></b-form-input>
                  </b-form-group> -->

                  <div class="col-12 mt-2 box-a-buttons text-center" v-if="!loading">
                    <b-button type="submit" class="btn-st-1 btn-color-1">Siguiente</b-button>
                  </div>
                  <div class="col-12 mt-2 box-a-buttons text-center" v-else>
                      <div class="fa-3x" ><i class="fas fa-spinner fa-pulse"></i></div><br/>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->

            <!-- Step 2 -->
            <div class="box-step step-1-2" v-show="step == 2">
              <h5 class="mb-3 line-title"><span>Sobre tu información</span></h5>
              <div class="row justify-content-center">
                <div class="col-12 col-form">
                  <b-form-group class="custom-group-1"
                    label="Nombre completo:">
                    <b-form-input
                      size="sm"
                      v-model="form.name"
                      type="text"
                      placeholder=""
                      
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="Agrega tu foto:">
                    <b-form-file  v-model="form.foto" accept="image/*" plain name="image"></b-form-file>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="INE frente:">
                    <b-form-file  v-model="form.INEFrente" accept=".pdf,image/*" plain name="inefront"></b-form-file>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="INE vuelta:">
                    <b-form-file  v-model="form.INEVuelta" accept=".pdf,image/*" plain name="ineback"></b-form-file>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="Comprobante de domicilio:">
                    <b-form-file  v-model="form.domicilio" accept=".pdf,image/*" plain name="proof_address"></b-form-file>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="El lugar es:">
                    <b-form-radio-group 
                      v-model="form.type_place" size="sm"
                      :aria-describedby="ariaDescribedby"
                      name="radio-sub-component">
                      <b-form-radio value="Propio"><span>Propio</span></b-form-radio>
                      <b-form-radio value="Rentado"><span>Rentado</span></b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>

                  <hr />

                  <b-form-group class="mb-1 custom-group-1"
                    label="Agrega fotos del lugar:">
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="Foto 1:">
                    <b-form-file  v-model="form.fotoLugar1" accept=".pdf,image/*" plain name="image1"></b-form-file>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="Foto 2:">
                    <b-form-file v-model="form.fotoLugar2" accept=".pdf,image/*" plain name="image2"></b-form-file>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="Foto 3:">
                    <b-form-file v-model="form.fotoLugar3" accept=".pdf,image/*" plain name="image3"></b-form-file>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                    label="Foto 4:">
                    <b-form-file v-model="form.fotoLugar4" accept=".pdf,image/*" plain name="image4"></b-form-file>
                  </b-form-group>
                </div>

                <div class="col-12 mt-2 box-a-buttons text-center" v-if="!loading">
                  <b-button type="button" class="mr-3 btn-st-1 btn-outl-color-1" @click="step = 1">Regresar</b-button>
                  <b-button type="submit" class="btn-st-1 btn-color-1">Siguiente</b-button>
                </div>
                <div class="col-12 mt-2 box-a-buttons text-center" v-else>
                      <div class="fa-3x" ><i class="fas fa-spinner fa-pulse"></i></div><br/>
                  </div>
              </div>
            </div>
            <!--  -->

            <!-- Step 3 -->
            <div class="box-step step-3" v-show="step == 3">
              <!-- Formulario para subir canchas -->
              <div>
                <h5 class="mb-3 line-title"><span>Agregar canchas</span></h5>
                <div class="row justify-content-center">
                  <div class="col-12 col-form">
                    <b-form class="row" @submit="addCancha">
                      <b-form-group class="col-12 custom-group-1"
                        label="Agregar foto:">
                        <b-form-file  v-model="formCanchas.foto" accept="image/*" plain name="imagecancha"></b-form-file>
                      </b-form-group> 

                      <b-form-group class="col-lg-7 pr-lg-0 custom-group-1"
                        label="Nombre de referencia:">
                        <b-form-input
                          size="sm"
                          v-model="formCanchas.name"
                          type="text"
                          placeholder=""
                          
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group class="col-lg-5 custom-group-1"
                        label="Tipo de cancha:">
                        <b-form-select size="sm"  v-model="formCanchas.type">
                          <b-form-select-option :value="null">Seleccionar</b-form-select-option>
                          <b-form-select-option value="Pasto">Pasto</b-form-select-option>
                          <b-form-select-option value="Leico">Leico</b-form-select-option>
                          <b-form-select-option value="Cemento">Cemento</b-form-select-option>
                        </b-form-select>
                      </b-form-group>

                      <b-form-group class="col-12 custom-group-1"
                        label="Descripción:">
                        <b-form-textarea
                          
                          size="sm"
                          v-model="formCanchas.description"
                          placeholder=""
                          rows="1"
                          max-rows="1"
                        ></b-form-textarea>
                      </b-form-group>

                      <div class="col-12 text-center" v-if="!loadingcancha">
                        <b-button type="submit" size="sm" class="btn-st-1 btn-color-2">Agregar</b-button>
                      </div>
                      <div class="col-12 text-center" v-else>
                        <div class="fa-3x" ><i class="fas fa-spinner fa-pulse"></i></div><br/>
                      </div>

                    </b-form>
                    <br>
                    <h5 class="mb-3 line-title"><span>Arma tu croquis con tus canchas</span></h5>
                    <div style="border: 1px solid;">
                        <v-stage ref="stage" 
                          :config="stageSize"
                          @mousedown="handleStageMouseDown"
                          @touchstart="handleStageMouseDown">
                          <v-layer ref="layer">

                            <v-group
                              v-for="item in canchasInd"
                              :key="'node' + item.id"
                              :config="{
                                x: 10,
                                y: 10,
                                draggable: true,
                              }"
                              
                            >
                              <v-rect
                                :key="'node' + item.id"
                                :config="item.config"
                              />
                              <v-text
                                :config="{
                                  text: item.name,
                                  x:25,
                                  y:50
                                }"
                              />
                            </v-group>
                             <v-transformer ref="transformer" />
                           <!-- <v-rect
                                v-for="item in canchasInd"
                                :key="item.id"
                                :config="item.config"
                                @transformend="handleTransformEnd"
                              />-->
                           
                          </v-layer> 
                        </v-stage>
                    </div>
                  </div>

                </div>
              </div>
              <!--  -->

              <!-- Canchas subidas -->
              <div class="mt-4">
                <div class="box-tennis-courts" v-for="(tc, tcInx) in canchasInd" :key="'tcInx-'+tcInx">
                  <!-- <div class="col col-court-photo">
                    <img class="placed-backg" src="public/images/shared/empty.png">
                  </div> -->

                  <div class="col col-court-name">
                    <h6>{{ tc.name }}</h6>
                  </div>

                  <div class="col col-court-type">
                    <h6>{{ tc.type }}</h6>
                  </div>

                  <div class="col col-btns">
                    <button type="button" class="t-150 btn-close" @click="removeCancha(tcInx)"></button>
                  </div>
                </div>
              </div>
              <!--  -->

           

              <div class="mt-4 text-center box-a-buttons" v-if="!loading">
                <b-button type="button" class="mr-3 btn-st-1 btn-outl-color-1" @click="step = 2">Regresar</b-button>
                <b-button type="submit" class="btn-st-1 btn-color-1">Siguiente</b-button>
              </div>
              <div class="col-12 mt-2 box-a-buttons text-center" v-else>
                      <div class="fa-3x" ><i class="fas fa-spinner fa-pulse"></i></div><br/>
                  </div>
            </div>
            <!--  -->

            <!-- Step 4 -->
            <div class="box-step step-4" v-show="step == 4">
              <h5 class="mb-3 line-title"><span>Agrega tu información bancaria.</span></h5>
              <span>Aqui realizaremos tus pagos quincenalmente de manera puntual. Esta información no sera compartida con nadie.</span>

              <div class="row justify-content-center">
                <div class="col-12 col-form">
                
                  <b-form-group class="custom-group-1"
                      label="Número de cuenta:">
                      <b-form-input
                        size="sm"
                        v-model="form.account_number"
                        placeholder=""
                      ></b-form-input>
                  </b-form-group>
                  
                 <!--  <b-form-group class="custom-group-1"
                      label="Banco:">
                      <b-form-input
                        size="sm"
                        v-model="form.bank"
                        placeholder=""
                      ></b-form-input>
                  </b-form-group> -->

                  <b-form-group class="custom-group-1"
                        label="Banco:">
                        <b-form-select size="sm"  v-model="form.bank">
                          <b-form-select-option :value="null">Seleccionar</b-form-select-option>
                          <b-form-select-option v-for="(bank,indx) in bankOpcs" :key="indx" :value="bank.name">{{bank.name}}</b-form-select-option>
                          
                        </b-form-select>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                      label="Clabe:">
                      <b-form-input
                        size="sm"
                        v-model="form.clabe"
                        placeholder=""
                        minlength="18"
                        maxlength="18"
                      ></b-form-input>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                      label="Comfirmar Clabe:">
                      <b-form-input
                        size="sm"
                        v-model="form.clabe_two"
                        placeholder=""
                        minlength="18"
                        maxlength="18"
                      ></b-form-input>
                  </b-form-group>

                  <b-form-group class="custom-group-1"
                      label="Beneficiario:">
                      <b-form-input
                        size="sm"
                        v-model="form.beneficiary"
                        placeholder=""
                      ></b-form-input>
                  </b-form-group>

                <div class="mt-4">
                  <b-form-checkbox class="custom-checkbox-1" v-model="form.checkbox" name="checkbox-1"
                    value="accepted" :unchecked-value="null">
                    <span>
                      Acepto los <router-link to="">Términos y condiciones de uso</router-link> y el <router-link to="">Aviso de privacidad</router-link>
                    </span>
                  </b-form-checkbox>
                </div>
                <div class="mt-4 text-center box-a-buttons" v-if="!loading">
                  <b-button type="button" class="mr-3 btn-st-1 btn-outl-color-1" @click="step = 3">Regresar</b-button>
                  <b-button type="submit" class="btn-st-1 btn-color-1">Registrarme</b-button>
                </div>
                <div class="col-12 mt-2 box-a-buttons text-center" v-else>
                      <div class="fa-3x" ><i class="fas fa-spinner fa-pulse"></i></div><br/>
                  </div>

                </div>
              </div>

             

            </div>
            <!--  -->

            <!-- Step 5 -->
            <div class="box-step step-4" v-if="step == 5">
              <p class="mb-1">
                <i class="fas fa-check-circle check-icon"></i>
              </p>

              <p class="mb-3">
                <img class="logo" src="public/images/logo-h-1.svg" alt="Padel+">
              </p>

              <h2 class="mb-1 title-st-1 text-orange">Registra completado exitosamente</h2>

              <h5 class="mb-3 text-black">¡Gracias por registrarse en Padel+!</h5>

              <p>
                Nos comunicaremos con usted a la brevedad posible.
              </p>

              <p class="mt-4">
                <router-link class="f-w-700" to="/"><i class="fas fa-home"></i> Regresar al Inicio</router-link>
              </p>
            </div>
            <!--  -->

          </div>
        </b-form>

        <sweet-modal :icon="modal.icon" ref="modal">
           
            <div v-html="modal.msg"></div>
            
        </sweet-modal>
        <sweet-modal blocking hide-close-button ref="modalloading">
        <div class="fa-3x"><i class="fas fa-spinner fa-pulse"></i></div><br>
        Cargando ...
        </sweet-modal>

      </div>
    </section>

    <div class="placed-backg img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/affiliation/image.jpg)' }"></div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
  export default {
    components: { VueGoogleAutocomplete },
  data(){
    return{
      step: 1,

      form:{
        id:null,
        name:null,
        name_field:null,
        email:null,
        phone:null,
        street:null,
        num_ext:null,
        num_int:null,
        state_id:null,
        town_id:null,
        neighborhood:null,
        zipcode:null,


        name_court:null,
        email:null,

        lat:20.66936598231273,
          lng:-103.34820660887827,
          address:null,
          clabe:null,
          clabe_two:null
      },

      formCanchas: {
        id:null,
        foto: null,
        nombre: null,
        tipo: null,
        descripcion: null,
      },

      canchasInd: [],

      states:[],
      towns:[],

      loading:false,
      loadingcancha:false,


      stageSize: {
        width: 540,
        height: 300
      },
      isDragging: false,
      selectedShapeName:'',
      currentPlace:null,
      mapLoaded:false,
      modal:{
        icon:null,
        msg:null
      },
      bankOpcs:[
          {name:'ABC CAPITAL'},
          {name:'ACTINVER'},
          {name:'AFIRME'},
          {name:'AKALA'},
          {name:'ARCUS'},
          {name:'ASP INTEGRA OPC'},
          {name:'AUTOFIN'},
          {name:'AZTECA'},
          {name:'BaBien'},
          {name:'BAJIO'},
          {name:'BANAMEX'},
          {name:'BANCO COVALTO'},
          {name:'BANCOMEXT'},
          {name:'BANCOPPEL'},
          {name:'BANCO S3'},
          {name:'BANCREA'},
          {name:'BANJERCITO'},
          {name:'BANKAOOL'},
          {name:'BANK OF AMERICA'},
          {name:'BANOBRAS'},
          {name:'BANORTE'},
          {name:'BANREGIO'},
          {name:'BANSI'},
          {name:'BANXICO'},
          {name:'BARCLAYS'},
          {name:'BBASE'},
          {name:'BBVA MEXICO'},
          {name:'BMONEX'},
          {name:'CAJA POP MEXICA'},
          {name:'CAJA TELEFONIST'},
          {name:'CB INTERCAM'},
          {name:'CIBANCO'},
          {name:'CI BOLSA'},
          {name:'CLS'},
          {name:'CoDi Valida'},
          {name:'COMPARTAMOS'},
          {name:'CONSUBANCO'},
          {name:'CREDICAPITAL'},
          {name:'CREDIT SUISSE'},
          {name:'CRISTOBAL COLON'},
          {name:'DONDE'},
          {name:'FINAMEX'},
          {name:'FINCOMUN'},
          {name:'FOMPED'},
          {name:'FONDO (FIRA)'},
          {name:'GBM'},
          {name:'HIPOTECARIA FED'},
          {name:'HSBC'},
          {name:'ICBC'},
          {name:'INBURSA'},
          {name:'INDEVAL'},
          {name:'INMOBILIARIO'},
          {name:'INTERCAM BANCO'},
          {name:'INVERCAP'},
          {name:'INVEX'},
          {name:'JP MORGAN'},
          {name:'KUSPIT'},
          {name:'LIBERTAD'},
          {name:'MASARI'},
          {name:'MIFEL'},
          {name:'MIZUHO BANK'},
          {name:'MONEXCB'},
          {name:'MUFG'},
          {name:'MULTIVA BANCO'},
          {name:'MULTIVA CBOLSA'},
          {name:'NAFIN'},
          {name:'OPM'},
          {name:'PAGATODO'},
          {name:'PROFUTURO'},
          {name:'SABADELL'},
          {name:'SANTANDER'},
          {name:'SCOTIABANK'},
          {name:'SHINHAN'},
          {name:'STP'},
          {name:'TACTIV CB'},
          {name:'UNAGRA'},
          {name:'VALMEX'},
          {name:'VALUE'},
          {name:'VECTOR'},
          {name:'VE POR MAS'},
          {name:'VOLKSWAGEN'},

      ]
    }
  },
  watch:{
    'form.state_id':function(){
        this.getTowns();
    }
  },

  methods: {
     handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.canchasInd.find(
        (r) => r.config.name === this.selectedShapeName
      );
      // update the state
      rect.config.x = e.target.x();
      rect.config.y = e.target.y();
      rect.config.rotation = e.target.rotation();
      rect.config.scaleX = e.target.scaleX();
      rect.config.scaleY = e.target.scaleY();

      // change fill
      //rect.config.fill = Konva.Util.getRandomColor();
    },
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.canchasInd.find((r) => r.config.name === name);
      if (rect) {
        this.selectedShapeName = name;
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne('.' + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    },
    onSubmit(event) {
      this.$refs.modalloading.open();
      event.preventDefault();

      if(this.step == 4){
          if (this.form.clabe != this.form.clabe_two) {
               this.step = 4;
               this.$refs.modalloading.close();
              alert('Los campos Clabe no coiciden');
          }
          else if (this.form.checkbox == null) {
              this.step = 4;
              this.$refs.modalloading.close();
              alert('Debes de aceptar los Términos y condiciones de uso y el Aviso de privacidad');
          }
          else{
              this.saveForm();
          }
          
      }
      if(this.step == 3){
        if(this.canchasInd.length > 0){
            this.step = 4;
            this.$refs.modalloading.close();
        }
        else{
          this.$refs.modalloading.close();
          alert('Necesita agregar al menos 1 cancha');
        }
      }
      if(this.step == 2){
        this.saveForm();
      }
      if(this.step == 1){
        if (this.form.name_field == null) {
          this.$refs.modalloading.close();
            alert('El campo "Nombre" es obligatorio');
            this.step = 1;
        }
        else if (this.form.email == null) {
          this.$refs.modalloading.close();
            alert('El campo "Correo electrónico" es obligatorio');
            this.step = 1;
        }
        else if (this.form.phone == null) {
          this.$refs.modalloading.close();
            alert('El campo "Télefono" es obligatorio');
            this.step = 1;
        }
        /*else if (this.form.street == null) {
            alert('El campo "Dirección" es obligatorio');
            this.step = 1;
        }
        else if (this.form.num_ext == null) {
            alert('El campo "No Ext" es obligatorio');
            this.step = 1;
        }
        else if (this.form.state_id == null) {
            alert('Seleccione un "Estado"');
            this.step = 1;
        }
        else if (this.form.town_id == null) {
            alert('Seleccione una "Ciudad"');
            this.step = 1;
        }*/
        else{
            this.saveForm();
        }
        
        
      }
    },

    addCancha(event) {
      this.$refs.modalloading.open();
      this.loadingcancha = true;
      event.preventDefault();

        var formData = new FormData();
        if (typeof jQuery('input[name="imagecancha"]')[0].files[0] !== 'undefined') {
            formData.append("imagecancha", jQuery('input[name="imagecancha"]')[0].files[0]);
        }
        formData.append("user_id", this.formCanchas.id);
        formData.append("name", this.formCanchas.name);
        formData.append("type", this.formCanchas.type);
        formData.append("description", this.formCanchas.description);


        axios.post(tools.url('/api/customersfields/'+this.form.id),formData).then((response)=>{
          var fieldd = response.data;
          this.canchasInd.push({...fieldd});
          this.loadingcancha = false;
          this.$refs.modalloading.close();
        }).catch((error)=>{
            console.log(error);
            this.loadingcancha = false;
            this.$refs.modalloading.close();
        });


        //this.canchasInd.push({...formData});

      this.formCanchas = {id:null, name: null, type: null, description: null, }
    },

    removeCancha(idx){
        this.$refs.modalloading.open();
        this.loadingcancha = true;
        axios.delete(tools.url('/api/customersfields/'+this.canchasInd[idx]['id'])).then((response)=>{
            this.$refs.modalloading.close();
            this.canchasInd.splice(idx, 1);
            this.loadingcancha = false;
        }).catch((error)=>{
          this.$refs.modalloading.close();
          console.log(error);
          this.loadingcancha = false;
        });
      
    },
    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.state_id){
        axios.get(tools.url('/api/towns/' + this.form.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },
    getAddressData: function (addressData, placeResultData, id) {
        $('#searchemap input.form-control').prop("readonly",false);
        this.form.lat = addressData.latitude;
        this.form.lng = addressData.longitude;

        this.form.address = placeResultData.formatted_address;
        this.currentPlace = placeResultData.formatted_address;
    },
    updateCoordinates(location) {
        this.form.lat = location.latLng.lat();
        this.form.lng = location.latLng.lng();

        var self = this;
        var geocoder = new google.maps.Geocoder();

        geocoder.geocode({
            latLng: location.latLng
          }, function(responses) {
            if (responses && responses.length > 0) {
              self.form.address = responses[0].formatted_address;
              self.currentPlace = responses[0].formatted_address;
            } 
        });
    },
    setPlace(event) {
        this.form.lat = event.latLng.lat();
        this.form.lng = event.latLng.lng();

        var geocoder = new google.maps.Geocoder();
        var self = this;
        geocoder.geocode({
            latLng: event.latLng
          }, function(responses) {
            if (responses && responses.length > 0) {
              self.form.address = responses[0].formatted_address;
              self.currentPlace = responses[0].formatted_address;
            } 
        });
    },
    saveForm(){
        this.loading = true;
        var formData = new FormData();
        
        if (typeof jQuery('input[name="imagefield"]')[0].files[0] !== 'undefined') {
            formData.append("imagefield", jQuery('input[name="imagefield"]')[0].files[0]);
        }
        
        if (typeof jQuery('input[name="image"]')[0].files[0] !== 'undefined') {
            formData.append("image", jQuery('input[name="image"]')[0].files[0]);
        }
        if (typeof jQuery('input[name="inefront"]')[0].files[0] !== 'undefined') {
            formData.append("inefront", jQuery('input[name="inefront"]')[0].files[0]);
        }
        if (typeof jQuery('input[name="ineback"]')[0].files[0] !== 'undefined') {
            formData.append("ineback", jQuery('input[name="ineback"]')[0].files[0]);
        }
        if (typeof jQuery('input[name="proof_address"]')[0].files[0] !== 'undefined') {
            formData.append("proof_address", jQuery('input[name="proof_address"]')[0].files[0]);
        }

        
        if (typeof jQuery('input[name="image1"]')[0].files[0] !== 'undefined') {
            formData.append("image1", jQuery('input[name="image1"]')[0].files[0]);
        }
        if (typeof jQuery('input[name="image2"]')[0].files[0] !== 'undefined') {
            formData.append("image2", jQuery('input[name="image2"]')[0].files[0]);
        }
        if (typeof jQuery('input[name="image3"]')[0].files[0] !== 'undefined') {
            formData.append("image3", jQuery('input[name="image3"]')[0].files[0]);
        }
        if (typeof jQuery('input[name="image4"]')[0].files[0] !== 'undefined') {
            formData.append("image4", jQuery('input[name="image4"]')[0].files[0]);
        }  
        
        //var fields_array = JSON.stringify(this.canchasInd);
        //formData.append('fields',fields_array);
        let stage = this.$refs.stage.getNode()
        let dataURL = stage.toDataURL()
        formData.append("map", dataURL);
        
        formData.append("id", this.form.id);
        formData.append("name", this.form.name);
        formData.append("name_field", this.form.name_field);
        formData.append("email", this.form.email);
        formData.append("phone", this.form.phone);
        /*formData.append("street", this.form.street);
        formData.append("num_ext", this.form.num_ext);
        formData.append("num_int", this.form.num_int);
        formData.append("state_id", this.form.state_id);
        formData.append("town_id", this.form.town_id);
        formData.append("neighborhood", this.form.neighborhood);
        formData.append("zipcode", this.form.zipcode);*/
        formData.append("lat", this.form.lat);
        formData.append("lng", this.form.lng);
        formData.append("address", this.form.address);
        formData.append("account_number", this.form.account_number);
        formData.append("bank", this.form.bank);
        formData.append("clabe", this.form.clabe);
        formData.append("beneficiary", this.form.beneficiary);
        formData.append("step", this.step);





        axios.post(tools.url('/api/customers'),formData).then((response)=>{
          this.form = response.data;
          this.$refs.modalloading.close();
          if (this.step == 1) {
              this.step = 2;
          }
          else if(this.step == 2){
              this.step = 3;
          }
          else if (this.step == 4) {
              this.step = 5;
          }
          this.loading = false;
          
        }).catch((error)=>{
           this.$refs.modalloading.close();
           this.modal.icon = "error";
            this.modal.msg = error.response.data.msg;
            
            this.$refs.modal.open();

          this.loading = false;
         
        });
    }


  },
  mounted(){
      this.getStates();
      var self = this;
      setTimeout(function(){
        self.mapLoaded = true;
          
      }, 2000)
  }
}
</script>

<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #dddddd;
}
</style>