<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide  v-for="(banner, index) in banners.movil" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="container oversized-container benefits-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2>¿Que ofrece Padel+ ?</h2>
        </div>

        <div class="col-md-6 col-lg-3 col-benefit">
          <img src="public/images/pages/home/benefit-1.jpg">

          <h5>Consulta y reserva canchas</h5>
        </div>

        <div class="col-md-6 col-lg-3 col-benefit">
          <img src="public/images/pages/home/benefit-2.jpg">

          <h5>Compra productos y accesorios</h5>
        </div>

        <div class="col-md-6 col-lg-3 col-benefit">
          <img src="public/images/pages/home/benefit-3.jpg">

          <h5>Encuentra con quien jugar</h5>
        </div>

        <div class="col-md-6 col-lg-3 col-benefit">
          <img src="public/images/pages/home/benefit-4.jpg">

          <h5>Transmisiones streaming de los partidos por PADEL + TV</h5>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: {
        /*pc: [
          { imagen: 'public/images/pages/home/banner-1.jpg' },
          { imagen: 'public/images/pages/home/banner-2.jpg' },
          { imagen: 'public/images/pages/home/banner-3.jpg' },
        ],
        movil: [
          { imagen: 'public/images/pages/home/banner-1-m.jpg' },
          { imagen: 'public/images/pages/home/banner-2-m.jpg' },
          { imagen: 'public/images/pages/home/banner-3-m.jpg' },
        ]*/
      },

      // == Carousel options ==
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          // dynamicBullets: true,
          clickable: true
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },
      // == ==
    }
  },
  methods:{

      getBanners(){
        axios.get(tools.url("/api/banners")).then((response)=>{
            this.banners = response.data;

          }).catch((error)=>{
              this.$parent.handleErrors(error);
          });
      },
  },
  mounted(){

      this.getBanners();
  }
}
</script>
