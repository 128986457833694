<template lang="html">
  <div id="about-us-page">

    <section class="container-fluid main-section">
      <div class="row align-items-center">
        <div class="col-md-5 col-lg-6 col-image">
          <!-- <img src="public/images/pages/about-us/image.jpg"> -->
        </div>

        <div class="col-md-7 col-lg-6 col-info">
          <div class="box-middle">
            <h2 class="mb-3 title-st-1">Empresa</h2>

            <p class="mb-4 text-center">
              <img src="public/images/pages/about-us/logo.png">
            </p>

            <div>
              <p class="text-center">
                Somos una plataforma 100% mexicana desarrollada por y para los amantes del PADEL.
              </p>
              <p class="mt-3 mb-3 text-center">
                Pretendemos que nuestra tecnología facilite los procesos diarios de:
              </p>

              <ol class="text-orange">
                <li>Agenda y reservación de canchas.</li>
                <li class="mt-1">Búsqueda de partidos/parejas para juego.</li>
                <li class="mt-1">Compra de productos y accesorios para Padel.</li>
                <li class="mt-1">Registro de resultados y autoevaluación de desempeño en el juego.</li>
                <li class="mt-1">Creación de comunidad y fomento de la sana convivencia.</li>
              </ol>

              <p class="mt-4 text-center">
                Padel plus continua creciendo y cada día afiliando mas canchas, afilia la tuya aquí o contáctanos para patrocinios y/o asociaciones.
              </p>
          </div>
          </div>
        </div>
      </div>
    </section>

    <div class="placed-backg img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/image.jpg)' }"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },
}
</script>
