require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

window.Vue=Vue;

//Instancia principal
const app = new Vue({
  el: '#app',
  router:Router,

  data(){
    return{
      appsLinks: {
        ios: 'https://apps.apple.com/mx/app/p%C3%A1del/id1643660531',
        android: 'https://play.google.com/store/apps/details?id=com.padel.plus'
      }
    }
  }
});
